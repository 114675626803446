
import './App.css';
import Page from './page';


function App() {
  return (
   
    <Page/>
   
  );
}

export default App;
